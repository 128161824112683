:root {
  --primary-font-color: #252525;
  --secondary-font-color: #ffffff;
  --primay-highlight-color: #cafbff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  header {
    position: sticky;
    top: 0;
    z-index: 999;
    @apply flex items-center justify-between shadow px-6 text-white bg-[#252525];
  }

  header .nav-content {
    @apply text-white flex flex-row gap-2 items-center w-full bg-[#252525]
  }

  header .nav-item {
    @apply h-full block;
    position: relative;
    z-index: 100;
  }

  header .dropdown {
    @apply relative
  }

  ;
}

header .dropdown-content {
  @apply absolute list-none bg-[#4e4e4e] text-white shadow px-1 py-1 min-w-full whitespace-nowrap;
  transform: translateY(-100%);
  transition: all .2s;
  left: 0;
  z-index: -1;
  opacity: 0;
}

header .dropbtn {
  @apply flex whitespace-nowrap items-center justify-between px-4 py-2;
}

header .dropdown:hover .dropdown-content {
  transform: translateY(0);
  opacity: 1;
}

.home-content h2 {
  @apply text-3xl tracking-wider text-center p-4;
}

.home-content p,
.home-content li {
  @apply text-sm;
}

header .nav-item::after {
  content: "";
  border-bottom: solid 1px;
  width: 0;
  display: block;
  transition: width .1s;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

header .dropdown:hover {
  background-color: #4e4e4e;
}

header .nav-item:hover::after {
  width: 100%;
}

.off-canvus {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.884);
  z-index: 999;
  color: #ffffff;
}

.off-canvus.off-canvus-bottom {
  bottom: calc(-128px + 2rem);
  transition: bottom 0.3s ease-in-out;
  right: 0;
  height: 128px;
  width: 100%;
}

.off-canvus.off-canvus-bottom.resizable::after {
  content: '';
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: 100%;
  cursor: ew-resize;
}

.off-canvus.active {
  box-shadow: -2px 0 5px #000;
}

.off-canvus.off-canvus-bottom.active {
  bottom: 0;
}

.off-canvus.off-canvus-right {
  top: 46.5px;
  right: 0;
  width: 30%;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  height: 100%;
}

.off-canvus.off-canvus-right.resizable::after {
  content: '';
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  cursor: ew-resize;
}

.off-canvus.off-canvus-right.active {
  right: 0 !important;
  transform: translateX(0);
}

.off-canvus .off-canvus-heading {
  display: grid;
  grid-template-columns: 64px 1fr;
  padding-top: 4px;
}

.off-canvus .off-canvus-heading h3 {
  padding-inline: 44px;
  text-align: center;
}

.off-canvus-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.off-canvus .off-canvus-content {
  padding: 20px;
  position: relative;
  display: grid;
  grid-template-rows: 1fr 8fr 1.5fr;
}

.nav-toggle {
  flex-direction: column;
  height: 22px;
  width: 22px;
}

.nav-toggle .bar {
  height: 3px;
  width: 100%;
  background-color: #fff;
  transition: all 50ms ease-in-out;
}

.nav-toggle:hover {
  cursor: pointer;
}

.nav-toggle.x :nth-of-type(1) {
  transform: translateY(5px);
  transform-origin: top left;
}

.nav-toggle.x :nth-of-type(2) {
  transform: rotate(-45deg);
  transform-origin: bottom left;
  width: 14px;
  display: none;
}

/* Tile line */

.timeline {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 20px 0px;
  height: fit-content;
}

.timeline .tooltip{
  top: 14px;
  transform: translateY(-100%);
  padding: 0.25rem;
  width: max-content;
}

.timeline .tooltip.left{
  left: -4px;
}

.timeline .group .tooltip.left::after{
  left: 0;
}
.timeline .tooltip.right{
  right: -4px;
}

.timeline .group .tooltip.right::after{
  right: 18px;
}


.timeline .group .tooltip::after{
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  background: inherit;
  transform: rotate(45deg) translateY(-50%);
  z-index: -1;
}

.timeline .point-container {
  display: flex;
  align-items: flex-end;
  padding-inline: 1rem;
}

.timeline .point-container .tl-point {
  display: flex;
  align-items: flex-end;
  transition: all 0.5s ease-in-out;
  opacity: 100%;
  margin-bottom: -0.5rem;
  position: relative;
  width: 0;
  cursor: pointer;
  scale: 1;
}

.timeline .point-container .tl-point:hover {
  scale: 1.1;
}


.timeline .point-container .tl-point::before {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  background-color: red;
  position: absolute;
}

.timeline .point-container .tl-point::after {
  content: "";
  position: absolute;
  width: 0.375rem;
  height: 0.375rem;
  background-color: #fff;
  bottom: 3px;
  transform: translate(48%);
}

.timeline .point-container .tl-point.circle::before,
.timeline .point-container .tl-point.circle::after {
  border-radius: 50%;
}

.timeline .point-container .tl-point.square::before,
.timeline .point-container .tl-point.square::after {
  border-radius: 15%;
}

.timeline .point-container .tl-point>span {
  transform: translateX(-30%);
}

.timeline .point-container .tl-point>span::before,
.timeline .point-container .tl-point>span::after {
  content: "";
  position: absolute;
  top: 1.3rem;
  background-color: red;
}

.timeline .point-container .tl-point>span::before {
  content: "";
  right: 50%;
  width: 6px;
  height: 4px;
  transform: translateX(50%);
}

.timeline .point-container .tl-point>span::after {
  content: "";
  right: calc(50% - 1px);
  width: 2px;
  height: calc(100% - 1.7em);
}

.timeline .line {
  height: 2px;
  background-color: red;
  width: 100%;
  box-shadow: 0 0 15px black;
}

.timeline .line::after,
.timeline .line::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 20px;
  background-color: red;
  transform: translateY(-50%);
  box-shadow: 0 0 15px black;
}

.timeline .line::after {
  right: 20px;
}

/* Utility classes */

.btn {
  background-color: grey;
  color: var(--secondary-font-color);
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  border: none;
  outline: none;
  transition: box-shadow 0.2s ease-in-out;
  text-decoration: none;
}

.btn:hover {
  outline-color: transparent;
  outline-style: solid;
  box-shadow: 0 0 0 4px var(--primay-highlight-color);
}

/* Map */

.main-map {
  width: 100%;
  height: 85%;
  background: #0000;
}