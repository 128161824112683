.leaflet-monuments {
    height: 47px;
    width: 47px;
    background-image: url("./img/icon_mouse.png");
    /* <div>Icons made by <a href="http://www.freepik.com" title="Freepik">Freepik</a> from <a href="http://www.flaticon.com" title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div> */
    background-repeat: no-repeat;
    background-position: center;
}

.leaflet-monuments-clicked {
    height: 47px;
    width: 47px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("./img/icon_mouse.png");
    background-color: #888888 !important;
    /* added */
    /*border-color: chartreuse !important;*/
}

.leaflet-monuments-cursor {
    cursor: url("./img/icon_mouse.png"), auto !important;
}

.mon-popup img {
    width: -webkit-fill-available;
}