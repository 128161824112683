.leaflet-ruler{
  height: 47px;
  width: 47px;
  background-image: url("./img/icon_ruler.png"); /* <div>Icons made by <a href="http://www.freepik.com" title="Freepik">Freepik</a> from <a href="http://www.flaticon.com" title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div> */
  background-repeat: no-repeat;
  background-position: center;
}

.leaflet-ruler-clicked{
  height: 47px;
  width: 47px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("./img/icon_ruler.png");
  background-color: #888888 !important; /* added */
  /*border-color: chartreuse !important;*/
}
.leaflet-bar{
  background-color: #ffffff;
}
.leaflet-control {
  cursor: pointer;
}
.result-tooltip.leaflet-tooltip{
  background-color: rgba(55, 55, 55, .9);
  border-radius: 50%;
}
.result-tooltip{
  transition: background-color 0.5s;
  pointer-events: auto !important;
  border: none !important;
  font-size: 14pt;
  padding: 0px;
  padding-top: 1px;
}

/*Remove Arrow of Tooltips*/
.leaflet-tooltip-top:before, 
.leaflet-tooltip-bottom:before, 
.leaflet-tooltip-left:before, 
.leaflet-tooltip-right:before {
    border: none !important;
}

.moving-tooltip{
  background-color: rgba(255, 255, 255, .9);
  border: none;
  font-size: 14pt;
  padding: 0px;
  padding-top: 1px;
}
.plus-length{
  padding-left: 45px;
}

.result-tooltip span {
  display: none;
}
.result-tooltip:hover span {
  display: unset;
}
.result-tooltip:hover {
  background-color: rgba(255, 255, 255, .9);
  border-radius: 3px;
}

